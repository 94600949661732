import React, { useState, useEffect, useCallback } from 'react'
import type { ShippingSla } from 'src/components/product/ProductKitLook/types'
import { useSession } from 'src/sdk/session'
import { useProductAbTestEvent } from 'src/sdk/analytics/hooks/useProductAbTestEvent'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import Spinner from 'src/components/common/Spinner'
import Truck from 'src/components/icons/Truck'
import Info from 'src/components/icons/Info'
import axios from 'axios'
import { regionalizeUser } from 'src/utils/regionalizeUser'
import { Skeleton } from '@acctglobal/skeleton'
import { get } from 'idb-keyval'
import { setUrlProductIdentifier } from 'src/utils/product/setUrlProductIdentifier'
import { isNewPDP } from 'src/utils/isNewPDP'

import InputMask from '../../ui/InputMask'
import { handleDeliverySlas } from './handleDeliverySlas'
import ShippingSimulationContainer from './ShippingSimulatorContainer'

import './shipping-simulator.scss'

type ShippingSimulatorRuleProps = {
  openTooltip: boolean
  handleOpenTooltip: (tooltipState: boolean, isMob: boolean) => () => void
  isMobile: boolean | null
}

const ShippingSimulatorRule = ({
  openTooltip,
  handleOpenTooltip,
  isMobile,
}: ShippingSimulatorRuleProps) => {
  return (
    <div className="shipping-simulator__cep-title-info">
      <div className="shipping-simulator__cep-title-tooltip">
        <div className="shipping-simulator__cep-title-tooltip-absolute">
          {openTooltip ? (
            <div className="shipping-simulator__cep-title-tooltip-container">
              <div className="shipping-simulator__cep-title-tooltip-content">
                Os prazos de entrega são válidos à partir da
                <span> confirmação do pagamento. </span>
                Compra realizadas via cartão de crédito são aprovadas mais
                rapidamente. Nossas entregas acontecem de
                <span> segunda a sábado </span> e caso não seja concluída por
                ausência do destinatário, ficará
                <span> automaticamente agendada </span>
                para o dia seguinte.
              </div>
            </div>
          ) : null}
        </div>
        <button
          className="shipping-simulator__cep-title-tooltip-open-button"
          onMouseEnter={handleOpenTooltip(true, isMobile ?? false)}
          onMouseLeave={handleOpenTooltip(false, isMobile ?? false)}
          onClick={handleOpenTooltip(!openTooltip, !isMobile ?? false)}
          aria-label="Informação - Simulação de frete"
        >
          <Info />
        </button>
      </div>
    </div>
  )
}

function showAndHideSkeleton(visible: boolean, isMobile: boolean | null) {
  if (!visible) {
    return (
      <Skeleton
        width={365}
        height={isMobile ? 369.39 : 520}
        backgroundColor="#F4F4F4"
      />
    )
  }

  return <></>
}

const ShippingSimulator = ({ items }: ShippingSimulatorProps) => {
  const { postalCode, person } = useSession()
  const [slas, setSlas] = useState<ShippingSimulation[]>([])
  const [pickupPointsApi, setPickupPointsApi] = useState<PickupPointsProps[]>()
  const [openTooltip, setOpenTooltip] = useState(false)
  const [inputState, setInputState] = useState('blank')
  const [currentCep, setCurrentCep] = useState(postalCode ?? '')
  const [isLoadingSlas, setIsLoadingSlas] = useState(false)
  const [itemsSimulator, setItemsSimulator] = useState<Item[]>()
  const [isToday, setIsToday] = useState<boolean>(false)

  const { isValidating, ...partialSession } = useSession()
  const { isMobile } = useWindowDimensions()
  const { sendProductAbTestEvent } = useProductAbTestEvent()

  const [userRegionalized, setUserRegionalized] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    const savedCEP = async () => {
      const regionData = navigator?.cookieEnabled && (await get('fs::session'))

      if (regionData?.postalCode) {
        setUserRegionalized(true)
      }

      setCurrentCep(regionData?.postalCode ?? '')
    }

    savedCEP()
    setVisible(true)
  }, [])

  const getSlas = useCallback(async () => {
    setIsToday(false)
    setIsLoadingSlas(true)
    setSlas([])
    if (!currentCep.match(/^[0-9]{5}-[0-9]{3}$/) || currentCep === '') {
      setInputState('invalid')
      setSlas([])
      setIsLoadingSlas(false)

      return
    }

    const { data: postalCodeData } = await axios.post(
      '/api/getPostalCodeData',
      {
        postalCode: currentCep,
      }
    )

    if (postalCodeData) {
      const { state, city } = postalCodeData

      if (!state || !city) {
        setInputState('invalid')
        setSlas([])
        setIsLoadingSlas(false)

        return
      }
    }

    if (!userRegionalized || postalCode !== currentCep) {
      await regionalizeUser({
        cep: currentCep,
        postalCodeData,
        partialSession,
      })

      const lastSelectedSku = items?.[0]?.id

      setIsLoadingSlas(false)

      setUrlProductIdentifier(lastSelectedSku ?? '')

      window.location.reload()

      return
    }

    const { slasFinal, pickupPoints } = await handleDeliverySlas({
      items,
      postalCode: currentCep,
    })

    setSlas(
      slasFinal?.filter(
        (slaFinal: ShippingSla) => !!slaFinal
      ) as ShippingSimulation[]
    )

    setPickupPointsApi(pickupPoints)

    setIsLoadingSlas(false)
  }, [currentCep, items, partialSession, postalCode, userRegionalized])

  useEffect(() => {
    if (
      userRegionalized &&
      (items?.find((item, index) => item?.id !== itemsSimulator?.[index]?.id) ||
        !itemsSimulator)
    ) {
      getSlas()
      setItemsSimulator(items)
    }
  }, [getSlas, items, itemsSimulator, userRegionalized])

  const sendSelectItemAbTestEventToAnalytics = () => {
    sendProductAbTestEvent({
      pdp_version: isNewPDP ? 'versao_2' : 'versao_1',
      action: 'form_submit',
      component: 'form',
      position_interaction: 'direita',
      element_name: 'calcular_frete',
      section: 'info_gerais',
      user_id: person?.id ?? null,
      user_logged: !!person?.id,
    })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (slas.length) {
      setSlas([])
      setIsToday(false)
    }

    setInputState('blank')
    const cep = e.target.value

    setCurrentCep(cep)
  }

  const handleOpenTooltip = (tooltipState: boolean, isMob: boolean) => () => {
    if (isMob) {
      return
    }

    setOpenTooltip(tooltipState)
  }

  showAndHideSkeleton(visible, isMobile)

  return (
    <div className="shipping-simulator">
      <div className="shipping-simulator__request-container">
        <div className="shipping-simulator__cep-title">
          {!isNewPDP && (
            <div className="shipping-simulator__cep-title-truck">
              <Truck />
            </div>
          )}
          {isNewPDP
            ? 'Frete e prazo de entrega'
            : 'Calcular frete e prazo de entrega'}
          <ShippingSimulatorRule
            openTooltip={openTooltip}
            handleOpenTooltip={handleOpenTooltip}
            isMobile={isMobile}
          />
        </div>
        <div className="shipping-simulator__cep-container">
          <div className={`shipping-simulator__input-container ${inputState}`}>
            <InputMask
              className={`shipping-simulator__input ${inputState}`}
              onChange={handleInputChange}
              mask="cep"
              value={currentCep}
              placeholder="Digite o CEP"
              inputMode="numeric"
            />
          </div>
          {isLoadingSlas ? (
            <div className="shipping-simulator__button">
              <Spinner />
            </div>
          ) : (
            <button
              className="shipping-simulator__button"
              onClick={() => {
                sendSelectItemAbTestEventToAnalytics()
                getSlas()
              }}
            >
              OK
            </button>
          )}
        </div>
      </div>
      {inputState === 'invalid' && (
        <div className="shipping-simulator__cep-invalid">
          CEP Inválido. Digite novamente!
        </div>
      )}
      {inputState === 'unavailable' && (
        <div className="shipping-simulator__cep-invalid">
          O produto não pode ser entregue para este endereço.
        </div>
      )}
      <ShippingSimulationContainer
        slas={slas}
        today={isToday}
        pickupPointsApi={pickupPointsApi}
        setIsToday={setIsToday}
      />
    </div>
  )
}

export default ShippingSimulator

interface ShippingSimulatorProps {
  items: Item[]
}
