import React from 'react'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import { useWishlistContext } from 'src/contexts/WishlistContext/wishlist-context'
import { useSession } from 'src/sdk/session'
import { useProductAbTestEvent } from 'src/sdk/analytics/hooks/useProductAbTestEvent'
import AddToWishlistModal from 'src/components/modal/AddToWishlistModal'
import SingleInputModal from 'src/components/modal/SingleInputModal'
import Breadcrumb from 'src/components/ui/Breadcrumb'
import WishlistHeart from 'src/components/ui/WishlistHeart'
import { ImageGallery } from 'src/components/ui/ImageGallery'
import { Skeleton } from '@acctglobal/skeleton'
import { OrderImagesByRegex } from 'src/utils/OrderImagesByRegex'
import { isNewPDP } from 'src/utils/isNewPDP'

import type { ArrayRequest } from './ProductDetails'
import ProductTags, { ProductTagsBottom } from './ProductTags'

import './product-details.scss'

interface ProductDetailsReturnProps {
  viewBreadcrumb: boolean
  formattedBreadcrumb?: Array<{
    item: string
    name: string
    position: number
  }>
  collectionId?: string
  isNewRelease: boolean
  productInfo: ArrayRequest | undefined
  hasPolarizedLens?: boolean
  images: Array<{
    url: string
    alternateName: string
  }>
  isSaveProductModalOpen: boolean
  product: BrowserProductQueryQuery['product']
  setIsSaveProductModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  visible: boolean
  isCreateWishlistModalOpen: boolean
  setIsCreateWishlistModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  createWishlist: (name: string, isDefault?: boolean) => Promise<boolean>
  width: 365 | 864
  height: 369.39 | 520
  renderVivaraYou: boolean
  clusterHighlights: Array<{
    id: string | null
    name: string | null
  }> | null
}

const ProductDetailsReturn = ({
  viewBreadcrumb,
  formattedBreadcrumb,
  collectionId,
  isNewRelease,
  productInfo,
  hasPolarizedLens,
  images,
  isSaveProductModalOpen,
  product,
  setIsSaveProductModalOpen,
  visible,
  isCreateWishlistModalOpen,
  setIsCreateWishlistModalOpen,
  createWishlist,
  width,
  height,
  renderVivaraYou,
  clusterHighlights,
}: ProductDetailsReturnProps) => {
  const { person } = useSession()
  const { sendProductAbTestEvent } = useProductAbTestEvent()
  const { removeProductFromWishlist } = useWishlistContext()

  const sendWishlistSelectItemAbTestEventToAnalytics = () => {
    sendProductAbTestEvent({
      pdp_version: isNewPDP ? 'versao_2' : 'versao_1',
      action: 'click',
      component: 'botao',
      position_interaction: 'esquerda',
      element_name: 'adicionar_a_wishlist',
      section: 'imagem_principal',
      user_id: person?.id ?? null,
      user_logged: !!person?.id,
    })
  }

  return (
    <>
      <div className="product-details / grid-content grid-section">
        {!viewBreadcrumb ? (
          <Skeleton width={325} height={18} backgroundColor="#F4F4F4" />
        ) : (
          !isNewPDP &&
          formattedBreadcrumb && (
            <div className="product-details__breadcrumb">
              <Breadcrumb breadcrumbList={formattedBreadcrumb} />
            </div>
          )
        )}
        {!viewBreadcrumb ? (
          <Skeleton width={325} height={18} backgroundColor="#F4F4F4" />
        ) : (
          isNewPDP &&
          formattedBreadcrumb && (
            <div
              className={isNewPDP ? `product-details__breadcrumb` : 'doNotShow'}
            >
              <Breadcrumb breadcrumbList={formattedBreadcrumb} />
            </div>
          )
        )}
        <section className="product-details__body product-details__image">
          <section className="product-details__left-section">
            <section className="product-details__image">
              {visible ? (
                <>
                  {collectionId !== undefined && (
                    <ProductTags
                      collections={clusterHighlights}
                      collectionId={collectionId}
                      isNewRelease={isNewRelease}
                    />
                  )}
                  {product && isNewPDP && (
                    <button
                      className="wishlist-button"
                      onClick={sendWishlistSelectItemAbTestEventToAnalytics}
                    >
                      <WishlistHeart
                        products={[product]}
                        hasBackgroundColor
                        isOnShelf={false}
                        onFilledHeartClick={() => {
                          removeProductFromWishlist([product])
                        }}
                        onEmptyHeartClick={() => {
                          setIsSaveProductModalOpen(true)
                        }}
                        pdpPath={window.location.pathname}
                      />
                    </button>
                  )}
                  <div className="product-details__ImageGallery">
                    <ImageGallery
                      images={OrderImagesByRegex(images)?.filter(
                        (img) =>
                          !img?.url?.includes('_hover') &&
                          !img?.url?.includes('-mon')
                      )}
                      videos={
                        productInfo ? productInfo?.items?.[0]?.videos : []
                      }
                    />
                    <ProductTagsBottom
                      collections={clusterHighlights}
                      isVivaraYou={renderVivaraYou}
                    />
                    {hasPolarizedLens && (
                      <div className="polarized-lens-tag">
                        <div className="polarized-lens-tag-content">
                          <p className="polarized-lens-tag-content-text">
                            Lentes Polarizadas
                          </p>
                        </div>
                      </div>
                    )}
                    {renderVivaraYou && (
                      <ProductTags
                        collections={clusterHighlights}
                        collectionId={collectionId}
                        isVivaraYou={renderVivaraYou}
                      />
                    )}
                  </div>
                </>
              ) : (
                <div className="product-details__ImageGallery">
                  <Skeleton
                    width={width}
                    height={height}
                    backgroundColor="#F4F4F4"
                  />
                </div>
              )}
            </section>
          </section>
        </section>
      </div>
      {isSaveProductModalOpen && (
        <AddToWishlistModal
          products={[{ ...product }]}
          isOpen={isSaveProductModalOpen}
          setIsOpen={setIsSaveProductModalOpen}
          openWishlistModal={() => setIsCreateWishlistModalOpen(true)}
        />
      )}
      {isCreateWishlistModalOpen && (
        <SingleInputModal
          title="Dê um nome para sua lista"
          btnText="Salvar"
          isOpen={isCreateWishlistModalOpen}
          setIsOpen={setIsCreateWishlistModalOpen}
          modalAction={createWishlist}
          setIsSaveProductModalOpen={setIsSaveProductModalOpen}
          onClose={() => setIsSaveProductModalOpen(true)}
        />
      )}
    </>
  )
}

export default ProductDetailsReturn
