/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import Slider from 'react-slick'
import { useSession } from 'src/sdk/session'
import { useProductAbTestEvent } from 'src/sdk/analytics/hooks/useProductAbTestEvent'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { Image } from 'src/components/ui/Image'
import { Button } from '@faststore/ui'
import Video from 'src/components/common/Video/index'
import InnerImageZoom from 'react-inner-image-zoom'
import IconVideoPlay from 'src/images/svg/icon-video-play'
import { isNewPDP } from 'src/utils/isNewPDP'

import './style.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export interface ImageElementData {
  url: string
  alternateName: string
}

interface ImageGalleryProps {
  images: ImageElementData[]
  videos: Array<{
    videoUrl: string
  }>
}

const slideImage = {
  aspectRatio: 1,
  width: isNewPDP ? 73 : 92,
  height: isNewPDP ? 72 : 92,
  breakpoints: [250, 360, 480, 720],
  layout: 'constrained' as const,
  backgroundColor: '#f0f0f0',
  options: {
    fitIn: true,
  },
}

const settingsMain = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  infinite: true,
}

const sliderMobile = {
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  fade: true,
}

const NUMBER_OF_CHARS_IN_IDS_KEY = 5

function ImageGallery({ images, videos }: ImageGalleryProps) {
  const settingsThumbs = {
    className: 'center',
    arrows: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    focusOnSelect: true,
  }

  const { width } = useWindowDimensions()

  const [nav1, setNav1] = useState<Slider>()
  const [nav2, setNav2] = useState<Slider>()
  const { person } = useSession()
  const { sendProductAbTestEvent } = useProductAbTestEvent()

  const isMobile = width < 1024

  const settings = width > 1023 ? settingsMain : sliderMobile

  const videoLikeImage = [
    {
      url: videos ? videos?.[0]?.videoUrl : '',
      alternateName: 'Vídeo do Produto',
    },
  ]

  const sliderSources: ImageElementData[] =
    videos && videos?.[videos?.length - 1]?.videoUrl !== ''
      ? images?.concat(videoLikeImage)
      : images

  const OnClickThumbs = () => {
    const _v = document?.getElementById('pdpVideo') as HTMLMediaElement

    _v?.play()
  }

  const getCroppedImageUrl = (
    isZoomImage?: boolean,
    imageUrl?: string
  ) => {
    if (!imageUrl) {
      return null
    }

    const cropTo1000x1000 = '-1000-1000'
    const cropTo1600x1600 = '-1600-1600'
    const startImageIdKeyIndex = imageUrl.indexOf('/ids/')
    const endImageIdKeyIndex = startImageIdKeyIndex + NUMBER_OF_CHARS_IN_IDS_KEY
    const endImageIdValueIndex = imageUrl.indexOf('/', endImageIdKeyIndex)
    const imageIdValue = imageUrl.substring(
      endImageIdKeyIndex,
      endImageIdValueIndex
    )

    return imageUrl.replace(
      imageIdValue,
      imageIdValue +
        (isZoomImage || isMobile ? cropTo1600x1600 : cropTo1000x1000)
    )
  }

  const sendSelectItemAbTestEventToAnalytics = () => {
    sendProductAbTestEvent({
      pdp_version: isNewPDP ? 'versao_2' : 'versao_1',
      action: 'click',
      component: 'imagem',
      position_interaction: 'esquerda',
      element_name: 'selecao_de_imagem',
      section: 'imagem_principal',
      user_id: person?.id ?? null,
      user_logged: !!person?.id,
    })
  }

  return (
    <>
      <div className="slider-container">
        <div className="zoom-container fake-color">
          <div className="zoom">
            {sliderSources?.length > 0 && (
              <Slider
                asNavFor={nav2}
                ref={(slider1) => slider1 && setNav1(slider1)}
                {...settings}
              >
                {sliderSources?.map(
                  (
                    image: { url: string; alternateName: string },
                    idx: number
                  ) => {
                    if (images.length - 1 >= idx) {
                      const croppedImageUrl = getCroppedImageUrl(
                        false,
                        image.url
                      )

                      const croppedZoomImageUrl = getCroppedImageUrl(
                        true,
                        image.url
                      )

                      return (
                        <div className="slider-image-core" key={idx}>
                          {!isMobile ? (
                            <InnerImageZoom
                              src={croppedImageUrl || image.url}
                              zoomSrc={croppedZoomImageUrl || image.url}
                              className="slider-image-zoom-inner-image"
                              imgAttributes={{ alt: image.alternateName }}
                              zoomType="hover"
                              zoomScale={0.55}
                              hideHint
                              hasSpacer
                              hideCloseButton={!isMobile}
                              zoomPreload
                            />
                          ) : (
                            <InnerImageZoom
                              src={image.url}
                              zoomSrc={croppedImageUrl || image.url}
                              className="slider-image-zoom-inner-image"
                              imgAttributes={{
                                alt: image.alternateName,
                              }}
                              zoomType="hover"
                              zoomScale={0.5}
                              hideHint
                              hasSpacer
                              fullscreenOnMobile
                              zoomPreload
                            />
                          )}
                        </div>
                      )
                    }

                    if (videos?.length) {
                      return (
                        <div className="slider-image-core" key={idx}>
                          <div className="collection-container__video">
                            {videos && (
                              <Video
                                videoSrcURL={videos?.[0]?.videoUrl}
                                videoTitle=""
                                controlsVideo
                                soundVideo
                                loopingVideo
                                autoVideo={false}
                                videoId="pdpVideo"
                              />
                            )}
                          </div>
                        </div>
                      )
                    }

                    return undefined
                  }
                )}
              </Slider>
            )}
          </div>
        </div>
      </div>
      <div className="slider-container-minor">
        {sliderSources?.length > 0 && (
          <Slider
            asNavFor={nav1}
            ref={(slider2) => slider2 && setNav2(slider2)}
            {...settingsThumbs}
          >
            {sliderSources.map(
              (image: { url: string; alternateName: string }, idx: number) => {
                if (images.length - 1 >= idx) {
                  return (
                    <div className="image-slide" key={idx}>
                      <button onClick={sendSelectItemAbTestEventToAnalytics}>
                        <Image
                          key={idx}
                          baseUrl={image.url}
                          alt={image.alternateName}
                          loading="lazy"
                          {...slideImage}
                        />
                      </button>
                    </div>
                  )
                }

                if (videos?.length) {
                  return (
                    <div className="image-slide video" key={idx}>
                      <Image
                        key={idx}
                        baseUrl={images[0].url}
                        alt={image.alternateName}
                        loading="eager"
                        {...slideImage}
                      />
                      <Button id="thumb-video" onClick={OnClickThumbs}>
                        <IconVideoPlay />
                      </Button>
                    </div>
                  )
                }

                return undefined
              }
            )}
          </Slider>
        )}
      </div>
    </>
  )
}

export default ImageGallery
